var hamburger = $('.hamburger');
var offcanvas = $('#offcanvas-menu');
var header = $('.header');
var body = $('body');
var logo = $('.brand');

hamburger.on('click', () => {
  body.addClass('menu-open');
  hamburger.toggleClass('is-active');
  header.toggleClass('mobile-nav-open');
  offcanvas.toggleClass('show');
});

offcanvas.on('hidden.bs.offcanvas', () => {
  hamburger.removeClass('is-active');
  header.removeClass('mobile-nav-open');
  body.removeClass('menu-open');
});

$(function() {
  $(document).on('click','.offcanvas-menu .nav-link:not(.dropdown-toggle), .offcanvas-menu .dropdown-item, .offcanvas-menu .btn-primary',() => {
    if( $('.navbar-toggler').is(':visible') ) {
      $('.navbar-toggler').trigger('click');
    }
  });
});

logo.on('click', (event) => {
  event.preventDefault();
});
